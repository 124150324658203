<template>
  <div class="crm__notes__form crm__notes__form--deminished">
    <div class="crm__notes__form__header">
      <div class="crm__notes__type">
        <div class="crm__notes__type__selected"><span>{{ noteMode }}</span></div>
      </div>
      <div @mouseleave="showActionsTask = false" class="crm__notes__type" v-if="noteMode === 'Задача'">
        <div @mouseover="showActionsTask = true" class="crm__notes__type__selected"><span>{{ taskType.name }}</span></div>
        <ul class="crm__notes__type__list" v-if="showActionsTask">
          <li :key="i" @click="taskType = item; showActionsTask = false" v-for="(item, i) in taskTypeSelect">{{ item.name }}</li>
        </ul>
      </div>
      <span class="crm__notes__div" v-if="noteMode === 'Задача'">для</span>
      <v-select appendToBody :clearable="false"
                :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                :value="{ label: editTaskManager.name, value: editTaskManager._id}"
                :calculate-position="calculatePosition"
                @input="onManagerChange"
                v-if="noteMode === 'Задача'">
        <template slot="no-options">Нет результатов</template>
        <template #open-indicator="{ attributes }">
          <div v-bind="attributes"></div>
        </template>
      </v-select>
      <span class="crm__notes__div" v-if="noteMode === 'Задача'">до</span>
      <date-picker
        :formatter="momentFormat"
        :shortcuts="shortcuts"
        :show-time-header="true"
        type="datetime"
        v-if="noteMode === 'Задача'"
        v-model="period"
      ></date-picker>
    </div>
    <div class="crm__notes__form__body">
      <textarea @keydown="addNoteIfPressCtrlAndEnter" rows="5" v-model="noteEdit"></textarea>
      <button @click="addNoteIfClickOnButton" class="crm__notes__send" type="button">
        <NoteSendIcon/>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import NoteSendIcon from '../svg/NoteSendIcon'
import ADD_TASKS from '@/graphql/mutations/AddTasks.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import { createPopper } from '@popperjs/core'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'LsgActionsNew',
  components: {
    NoteSendIcon,
    DatePicker
  },
  props: {
    ids: Array,
    type: String
  },
  data () {
    return {
      editTaskManager: {
        _id: null,
        name: ''
      },
      placement: 'bottom-start',
      noteMode: 'Задача',
      noteEdit: '',
      period: new Date(Date.now()),
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY HH:mm').toDate() : null
        }
      },
      shortcuts: [
        {
          text: 'Через 15 минут',
          onClick () {
            return moment().add(15, 'minutes').toDate()
          }
        },
        {
          text: 'Через 30 минут',
          onClick () {
            return moment().add(30, 'minutes').toDate()
          }
        },
        {
          text: 'Через час',
          onClick () {
            return moment().add(1, 'h').toDate()
          }
        },
        {
          text: 'Сегодня',
          onClick () {
            return moment().endOf('d').toDate()
          }
        },
        {
          text: 'Завтра',
          onClick () {
            return moment().add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'До конца недели',
          onClick () {
            return moment().endOf('w').endOf('d').toDate()
          }
        },
        {
          text: 'Через неделю',
          onClick () {
            return moment().endOf('w').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Через месяц',
          onClick () {
            return moment().endOf('M').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Через год',
          onClick () {
            return moment().endOf('y').add(1, 'd').endOf('d').toDate()
          }
        }
      ],
      noteModeSelect: ['Задача'],
      showActionsTask: false,
      taskTypeSelect: [
        {
          value: 'Call',
          name: 'Звонок'
        }, {
          value: 'Meeting',
          name: 'Переговоры'
        }, {
          value: 'WriteEmail',
          name: 'Написать письмо'
        }, {
          value: 'Get_docs',
          name: 'Получить документы'
        }, {
          value: 'Action',
          name: 'Прочее'
        }],
      taskType: {
        value: 'Call',
        name: 'Звонок'
      }
    }
  },
  created () {
    this.editTaskManager = this.$store.state.me
  },
  methods: {
    calculatePosition (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            }
          }]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    onResize () {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 768) {
        this.currentNotes.sort(function (a, b) {
          let dateA = new Date(a.createdAt)
          let dateB = new Date(b.createdAt)
          return dateB - dateA
        })
      } else {
        this.currentNotes.sort(function (a, b) {
          let dateA = new Date(a.createdAt)
          let dateB = new Date(b.createdAt)
          return dateA - dateB
        })
      }
    },
    async addTask () {
      let currentInput = {
        element_type: this.type,
        task_type: this.taskType.value,
        is_completed: false,
        complete_till_at: this.period,
        responsible_user_id: this.editTaskManager._id,
        text: this.noteEdit
      }
      await this.$apollo.mutate({
        mutation: ADD_TASKS,
        variables: {
          ids: this.ids,
          input: currentInput
        },
        update: (store, { data }) => {
          this.$router.go()
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    addNoteIfPressCtrlAndEnter (e) {
      if (e.keyCode === 13 && e.ctrlKey) {
        e.preventDefault()
        if (this.noteMode === 'Задача') this.addTask()
      }
    },
    addNoteIfClickOnButton (e) {
      e.preventDefault()
      if (this.noteMode === 'Задача') this.addTask()
    },
    onManagerChange (item) {
      if (item) {
        this.editTaskManager._id = item.value
        this.editTaskManager.name = item.label
      } else {
        this.editTaskManager._id = null
        this.editTaskManager.name = null
      }
    }
  }
}
</script>
