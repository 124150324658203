<template>
  <div>
    <div class="tbl" :class="{'tbl--divided': $store.state.checkedLeads.length}">
      <div class="tbl__header" v-if="$store.state.data.trades.showFilter">
        <div class="tbl__filter">
          <label class="tbl__filter__item" v-if="$store.state.leadStatusList.length && showFilter('status')">
            <v-select appendToBody
                      :options="$store.state.leadStatusListClear.filter(elem => elem.value !== 'atWork').map(item => ({ value: item.value, label: item.title }))"
                      placeholder="Статус сделки"
                      @input="onLeadStatusChange"
                      :value="filter.status ? { value: filter.status, label: $store.state.leadStatusListClear.find(s => s.value === filter.status).title} : null"
            >
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </label>
          <label class="tbl__filter__item" v-if="showFilter('responsible')">
            <v-select appendToBody
                      :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                      placeholder="Выберите менеджера"
                      @input="onUserChange"
                      :value="filter.responsible_user_id ? { value: filter.responsible_user_id, label: $store.state.allUsers.find(s => s.id === filter.responsible_user_id).name} : null"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </label>
          <label class="tbl__filter__item" v-if="showFilter('group')">
            <v-select appendToBody
                      placeholder="Выберите подразделения"
                      :options="$store.state.allGroups.map(item => ({ value: item.id, label: item.name }))"
                      @input="onGroupChange"
                      multiple
                      :value="filter.group_id ? $store.state.allGroups.filter(s => filter.group_id.includes(s.id)).map(item => ({ value: item.id, label: item.name })) : []"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </label>
          <label class="tbl__filter__item" v-if="showFilter('tags')">
            <TagsInput :key="tagsUpdatedKey" :tags="filter.tags ? $store.state.allTags.filter(s => filter.tags.includes(s.id)).map(item => ({value: item.id, label: item.name})) : []" @input="onTagsChanged"/>
          </label>
          <label class="tbl__filter__item" v-if="showFilter('creator')">
            <v-select appendToBody
                      :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                      placeholder="Кем создано"
                      @input="onCreatedChange"
                      :value="filter.created_by ? { value: filter.created_by, label: $store.state.allUsers.find(s => s.id === filter.created_by).name} : null"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </label>
          <div class="checkboxFilter" v-if="filter.responsible_user_id && !filter.created_by" style="display: none">
            <input type="checkbox" id="checkbox" @click="filterRespId" v-model="checked">
            <label for="checkbox">Переданные</label>
          </div>
        </div>
        <div class="tbl__settings">
          <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
            <a href="#" class="tbl__setting" @click.prevent="switchColumnModal">
              <SettingsIcon/>
            </a>
          </mq-layout>
          <div class="tbl__type">
            <a href="#" :class="{ active: $store.state.data.trades.view === 'table'}" @click.prevent="$store.state.data.trades.view = 'table'">
              <TypeTable/>
            </a>
            <a href="#" :class="{ active: $store.state.data.trades.view === 'kanban'}" @click.prevent="$store.state.data.trades.view = 'kanban'">
              <TypeKanban/>
            </a>
          </div>
        </div>
      </div>
      <div class="tbl__actions" v-if="$store.state.checkedLeads.length">
        <div class="tbl__actions__main">
          <div class="tbl__actions__count">
            <span>Выбрано: {{ $store.state.checkedLeads.length }}</span>
          </div>
          <div class="tbl__actions__items">
            <div class="tbl__actions__item">
              <a href="#" type="button" :class="{'active': actionType === 'addlead'}" @click.prevent="actionType = 'addlead'">
                <i aria-hidden="true">
                  <PlusIco/>
                </i>
                <span>Добавить задачу</span>
              </a>
              <div class="table-actions__drop">
                <LsgActionsNew type="Lead" v-if="actionType === 'addlead'" v-click-outside="vcoConfig" :ids="$store.state.checkedLeads"/>
              </div>
            </div>
            <div class="tbl__actions__item">
              <a href="#" :class="{'active': actionType === 'switchStage'}" @click.prevent="actionType = 'switchStage'">
                <i aria-hidden="true">
                  <switch-icon/>
                </i>
                <span>Изменить этап</span>
              </a>
              <div class="table-actions__drop" v-if="actionType === 'switchStage'">
                <ul class="table-actions__drop-down" v-click-outside="vcoConfigSimple">
                  <li v-for="(status, index) in $store.state.leadStatusListClear" :key="index" class="table-actions__li">
                    <a class="table-actions__link" href="#" @click="setStatus(status.value)">{{ status.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tbl__actions__item">
              <a href="#" :class="{'active': actionType === 'switchResponsible'}" @click.prevent="actionType = 'switchResponsible'">
                <i aria-hidden="true">
                  <responsible-icon/>
                </i>
                <span>Сменить ответственного</span>
              </a>
              <div class="table-actions__drop" v-if="actionType === 'switchResponsible'">
                <div class="table-actions__container">
                  <v-select appendToBody v-click-outside="vcoConfig" :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="Выберите исполнителя" @input="onResponsibleChange">
                    <template slot="no-options">Нет результатов</template>
                    <template #open-indicator="{ attributes }">
                      <div v-bind="attributes">
                        <DownIcon/>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tbl__actions__rest">
          <a href="#" class="bold" @click.prevent="$store.dispatch('switchShowCloseTradeModal')">
            <span>Закрыть</span>
          </a>
          <a v-if="$store.state.me.role === 'admin'" href="#" class="red bold" @click.prevent="$store.dispatch('switchShowDeleteTradeModal')">
            <span>Удалить</span>
          </a>
        </div>
      </div>
      <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
        <vue-good-table
          :columns="$store.state.data.trades.columns"
          :pagination-options="{enabled: true, perPage: perPage}"
          :rows="$store.state.allLeads.length ? $store.state.allLeads : []"
          :select-options="{
            selectOnCheckboxOnly: true,
            selectionText: 'строка выбрана',
            clearSelectionText: 'очистить',
            enabled: true
          }"
          @on-selected-rows-change="selectedRowsChange"
          @on-sort-change="sortingField2"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <template v-if="isLoading">
              <div class="sceleton"></div>
            </template>
            <template v-else>
              <router-link class="tag" :class="getStatusColor(props.row.status)" :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'status'">{{ props.row.status }}</router-link>
              <router-link :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'date'">{{ getDate(props.row.date) }}</router-link>
              <router-link :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'projectNumber'">{{ props.row.projectNumber || '' }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.contracts[0].id } }" v-if="props.column.field === 'contract' && props.row.contracts.length && props.row.contracts[0]">{{ props.row.contracts[0] ? `${props.row.contracts[0].name}${props.row.contracts.length > 1 ? ` (+${props.row.contracts.length - 1})` : ''}` : '' }}</router-link>
              <router-link class="tag" :class="getStatusColor(getStage(props.row.status, props.row.contracts))" :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'stage'">{{ getStage(props.row.status, props.row.contracts) }}</router-link>
              <router-link :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'group_id'">{{ props.row.group && props.row.group.name ? props.row.group.name : '' }}</router-link>
              <router-link :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'company_id'">{{ props.row.company && props.row.company.name ? props.row.company.name : ''}}</router-link>
              <router-link :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'responsible_user_id'">{{ props.row.responsible_user && props.row.responsible_user.name ? props.row.responsible_user.name : '' }}</router-link>
              <router-link :to="{ name: 'trade', params: { id: props.row._id } }" v-if="props.column.field === 'creator'">{{ props.row.creator.name }}</router-link>
            </template>
          </template>
          <div slot="emptystate">Данных нет</div>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="vgt-wrap__footer vgt-clearfix">
              <div class="footer__row-count vgt-pull-left">
                <span class="footer__row-count__label">Показано</span>
                <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                  <option :value="200">200</option>
                </select>
              </div>
            </div>
            <paginate
              :click-handler="setPage"
              :page-count="pageCount"
              :value="page + 1"
              container-class="tbl__pagination vgt-pull-right"
              prev-text="&lsaquo;"
              next-text="&rsaquo;">
              <span slot="prevContent">
                <ArrowLeftIcon/>
              </span>
              <span slot="nextContent">
                <ArrowRightIcon/>
              </span>
            </paginate>
          </template>
        </vue-good-table>
      </mq-layout>
      <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
        <table>
          <thead>
          <tr>
            <th>
              <div class="tbl__cols">
                <span :class="getSortableClass('status')" @click="sortingField2('status')">Статус</span>
                <span :class="getSortableClass('date')" @click="sortingField2('date')">Дата</span>
              </div>
            </th>
            <th>
              <div class="tbl__cols">
                <span :class="getSortableClass('group_id')" @click="sortingField2('group_id')">Подразделение</span>
                <span :class="getSortableClass('company_id')" @click="sortingField2('company_id')">Компания</span>
              </div>
            </th>
            <th>
              <div class="tbl__cols">
                <span :class="getSortableClass('responsible_user_id')" @click="sortingField2('responsible_user_id')">Менеджер</span>
              </div>
            </th>
          </tr>
          </thead>
          <tbody v-if="isLoading">
            <!-- <td colspan="3">Загрузка...</td> -->
            <tr v-for="(_, index) in Array(10).fill(0)" :key="index">
              <td v-for="(_, index2) in Array(3).fill(0)" :key="index2">
                <div class="tbl__content sceleton"></div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="$store.state.allLeads && $store.state.allLeads.length">
            <tr :key="index" v-for="(lead, index) in $store.state.allLeads">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedLeads.includes(lead._id) ? 'active' : ''" v-model="$store.state.checkedLeads" :value="lead._id"></checkbox>
                  <div class="tbl__content">
                    <router-link class="tag" :class="getStatusColor(lead.status)" :to="{name: 'trade', params: {id: lead._id }}">{{ lead.status }}</router-link>
                    <router-link :to="{name: 'trade', params: {id: lead._id }}">{{ getDate(lead.date) }}</router-link>
                  </div>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">
                    {{ lead.group && lead.group.name ? lead.group.name : '' }}
                  </router-link>
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">
                    {{ lead.company && lead.company.name ? lead.company.name : '' }}
                  </router-link>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">
                    {{ lead.responsible_user && lead.responsible_user.name ? lead.responsible_user.name : '' }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="5">Нет результатов</td>
          </tr>
          </tbody>
        </table>
      </mq-layout>
      <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
        <table>
          <tbody v-if="isLoading">
            <tr v-for="(_, index) in Array(5).fill(0)" :key="index">
              <td colspan="1">
                <div class="tbl__content__container sceleton sceleton--block"></div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="$store.state.allLeads && $store.state.allLeads.length">
          <tr :key="index" v-for="(lead, index) in $store.state.allLeads">
            <td>
              <div class="tbl__content__container">
                <checkbox :class-name="$store.state.checkedLeads.includes(lead._id) ? 'active' : ''" v-model="$store.state.checkedLeads" :value="lead._id"></checkbox>
                <div class="tbl__content">
                  <router-link class="tag" :class="getStatusColor(lead.status)" :to="{name: 'trade', params: {id: lead._id }}">{{ lead.status }}</router-link>
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">{{ getDate(lead.date) }}</router-link>
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">
                    {{ lead.group && lead.group.name ? lead.group.name : '' }}
                  </router-link>
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">
                    {{ lead.company && lead.company.name ? lead.company.name : '' }}
                  </router-link>
                  <router-link :to="{name: 'trade', params: {id: lead._id }}">
                    {{ lead.responsible_user && lead.responsible_user.name ? lead.responsible_user.name : '' }}
                  </router-link>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="1">Нет результатов</td>
          </tr>
          </tbody>
        </table>
      </mq-layout>
    </div>
    <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
      <div class="tbl__footer__item left"></div>
      <div class="tbl__footer__item">
        <paginate
          :page-count="pageCount"
          :value="page + 1"
          :click-handler="setPage"
          container-class="tbl__pagination vgt-pull-right"
          prev-text="&lsaquo;"
          next-text="&rsaquo;">
          <span slot="prevContent">
            <ArrowLeftIcon/>
          </span>
          <span slot="nextContent">
            <ArrowRightIcon/>
          </span>
        </paginate>
      </div>
      <div class="tbl__footer__item right">
        <label class="tbl__pagination__info">
          Показано
          <span class="bold">{{ perPage }}</span>
          из
          <span class="bold">{{ _allLeadsMeta }}</span>
        </label>
      </div>
    </mq-layout>
  </div>
</template>

<script>
import moment from 'moment'
import { extendMoment } from 'moment-range'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import SwitchIcon from '@/components/svg/SwitchIcon'
import ResponsibleIcon from '@/components/svg/ResponsibleIcon'
import UPDATE_LEADS from '@/graphql/mutations/UpdateLeads.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ArrowRightIcon from '@/components/svg/arrow_right'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import SettingsIcon from '@/components/svg/settings'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'
import DownIcon from '@/components/svg/DownIcon'
import PlusIco from '@/components/svg/plus'
import LsgActionsNew from '../../../components/ui/LsgActionsNew'
import gql from 'graphql-tag'

const _graphQlHelper = new GraphQLHelper()
const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')

export default {
  name: 'TradeTable',
  components: {
    LsgActionsNew,
    PlusIco,
    DownIcon,
    TypeKanban,
    TypeTable,
    SettingsIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ResponsibleIcon,
    SwitchIcon,
    TagsInput
  },
  props: {
    isFilters: {
      type: Boolean,
      required: true
    },
    createdBy: {
      type: Boolean,
      required: true
    },
    callCenter: {
      type: Boolean,
      required: true
    },
    form: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      checked: false,
      tagsUpdatedKey: 123,
      page: 0,
      perPage: 50,
      isLoading: true,
      vcoConfigSimple: {
        middleware: event => {
          return !event.target.classList.contains('table-actions__link')
        },
        handler: () => {
          this.actionType = null
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      vcoConfig: {
        handler: () => {
          this.actionType = null
        },
        middleware: (event) => {
          return this.hasSomeParentTheClass(event.target, '.mx-calendar')
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      actionType: null,
      sort: {
        field: 'date',
        order: 'desc'
      },
      filter: {
        st: null,
        status: null,
        responsible_user_id: null,
        created_by: null,
        group_id: [],
        createdAt: null,
        updatedAt: null,
        tags: [],
        callCenter: false,
        createdBy: false,
        form: false,
        hasContracts: null
      },
      // eslint-disable-next-line vue/no-reserved-keys
      _allLeadsMeta: 0
    }
  },
  watch: {
    'callCenter' (val) {
      this.filter.callCenter = val
      this.loadData()
    },
    'form' (val) {
      this.filter.form = val
      if (this.filter.form) {
        this.filter.responsible_user_id = '5e3c2055c14c514a0b4861e8'
        this.filter.created_by = '5ed9e0acc22e512fa8923ea1'
        const formTag = this.$store.state.allTags.filter(elem => elem.name === 'Интернет-форма')
        this.filter.tags = formTag.map(elem => elem.id)
      } else {
        this.filter.responsible_user_id = null
        this.filter.created_by = null
        this.filter.tags = []
      }
      this.tagsUpdatedKey++
      this.loadData()
    },
    'createdBy' (val) {
      this.filter.createdBy = val
      this.loadData()
    },
    '$store.state.data.trades.sort2' () {
      this.filter.st = this.$store.state.data.trades.sort2
      this.onStatusChange()
      this.loadData()
    },
    '$store.state.data.trades.sortDate' () {
      this.filter.st = this.$store.state.data.trades.sort2
      this.onStatusChange()
      this.loadData()
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.$store.state._allLeadsMeta / this.perPage)
    },
    selectAll: {
      get () {
        return this.$store.state.allLeads ? this.$store.state.checkedLeads.length === this.$store.state.allLeads.length : false
      },
      set (value) {
        if (value) {
          this.$store.state.checkedLeads = this.$store.state.allLeads.map(item => item._id)
        } else {
          this.$store.state.checkedLeads = []
        }
      }
    }
  },
  apollo: {
    _allLeadsMeta () {
      return {
        query: gql`query($filter: LeadFilter) {
          _allLeadsMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getCurrentFilter
          }
        },
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data._allLeadsMeta.count || 0
        }
      }
    }
  },
  created () {
    this.filter.callCenter = this.callCenter
    this.filter.form = this.form
    this.filter.createdBy = this.createdBy
    this.page = this.$store.state.data.trades.page
    this.perPage = this.$store.state.data.trades.perPage
    this.sort = this.$store.state.data.trades.sort
    this.filter = this.$store.state.data.trades.filter
    this.checked = this.$store.state.data.trades.checked
    this.loadData()
  },
  beforeDestroy () {
    this.$store.state.data.trades.page = this.page
    this.$store.state.data.trades.perPage = this.perPage
    this.$store.state.data.trades.sort = this.sort
    this.$store.state.data.trades.filter = this.filter
    this.$store.state.data.trades.checked = this.checked
  },
  methods: {
    showFilter (filterField) {
      const findedFilter = this.$store.state.data.trades.filters.find(filter => (filterField === filter.field))

      if (!findedFilter) return false

      return !findedFilter.hidden
    },
    getStage (leadStatus, contracts) {
      if (contracts && contracts.length && contracts[0]) {
        if (contracts.length === 1) {
          return contracts[0].status
        } else {
          return `${contracts[0].status} (+${contracts.length - 1})`
        }
      }
      return leadStatus
    },
    filterRespId () {
      this.checked = !this.checked
      this.loadData()
    },
    hasSomeParentTheClass (element, classname) {
      if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true
      return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname)
    },
    switchColumnModal () {
      this.$store.state.data.trades.columnModal = !this.$store.state.data.trades.columnModal
    },
    selectedRowsChange ({ selectedRows }) {
      this.$store.state.checkedLeads = selectedRows.map(row => row.id)
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
      this.loadData()
    },
    getRange (status) {
      switch (status) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Tomorrow).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Tomorrow).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Week).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.trades.sortDate[0]).startOf('day').format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.trades.sortDate[1]).endOf('day').format('YYYY-MM-DD')
          }
        default:
          return null
      }
    },
    onStatusChange () {
      if (this.filter.st === null) {
        this.filter.createdAt = null
      } else {
        this.filter.createdAt = this.getRange(this.filter.st)
      }
    },
    getStatusColor (status) {
      switch (status) {
        case 'Не обработано':
          return 'tag--blue'
        case 'Переговоры':
          return 'tag--purple'
        case 'Сбор документов':
          return 'tag--yellow'
        case 'Создание':
          return 'tag--dark-blue'
        case 'Рассмотрение':
          return 'tag--orange'
        case 'Одобрен':
          return 'tag--green'
        case 'Отказ служб':
          return 'tag--red'
        case 'Готов к финансированию':
          return 'tag--purple'
        case 'Профинансирован':
          return 'tag--green'
        case 'Согласование':
          return 'tag--orange'
        case 'Действует':
          return 'tag--dark-blue'
      }
    },
    setPage (page) {
      this.page = page - 1
      this.loadData()
    },
    async setStatus (status) {
      await this.$apollo.mutate({
        mutation: UPDATE_LEADS,
        variables: {
          ids: this.$store.state.checkedLeads,
          status: status
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.loadData()
      this.setPage(1)
    },
    onGroupChange (e) {
      this.filter.group_id = e.map(item => item.value)
      this.loadData()
      this.setPage(1)
    },
    onUserInput (value) {
      if (value === '') {
        this.filter.responsible_user_id = null
        this.loadData()
      }
    },
    async onResponsibleChange (user) {
      if (user) {
        await this.$apollo.mutate({
          mutation: UPDATE_LEADS,
          variables: {
            ids: this.$store.state.checkedLeads,
            responsible_user_id: user.value
          }
        }).then(() => {
          this.$router.go()
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при изменении ответственного в сделках'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
      }
    },
    onLeadStatusChange (status) {
      if (status) {
        this.filter.status = status.value
      } else {
        this.filter.status = null
      }
      this.loadData()
      this.setPage(1)
    },
    onUserChange (user) {
      this.checked = false
      if (user) {
        this.filter.responsible_user_id = user.value
      } else {
        this.filter.responsible_user_id = null
      }
      this.loadData()
      this.setPage(1)
    },
    onCreatedChange (user) {
      if (user) {
        this.filter.created_by = user.value
      } else {
        this.filter.created_by = null
      }
      this.loadData()
      this.setPage(1)
    },
    async loadData (e) {
      this.isLoading = true
      await this.$store.dispatch('allLeads', { context: this, variables: { page: this.page, perPage: this.perPage, filter: this.getTradeFilter(), sortField: this.sort.field, sortOrder: this.sort.order, checked: this.checked }, isMore: false }).then(() => {
        this.isLoading = false
      })
    },
    getTradeFilter () {
      let currentFilter = {}
      if (this.filter.status && this.filter.status !== 'atWork') currentFilter.status = this.filter.status
      if (this.filter.status === 'atWork') currentFilter.statuses = ['first', 'documents', 'decision', 'created', 'approved', 'dl']
      if (this.filter.responsible_user_id) currentFilter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.created_by) currentFilter.created_by = this.filter.created_by
      if (this.filter.group_id.length) currentFilter.group_id = this.filter.group_id
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      if (this.filter.form) currentFilter.form = this.filter.form
      if (this.filter.createdBy) currentFilter.created_by = '5ed9e0acc22e512fa8923ea1'
      if (this.filter.callCenter) currentFilter.callCenter = this.filter.callCenter
      if (this.filter.hasContracts !== null) currentFilter.hasContracts = this.filter.hasContracts
      if (this.filter.createdAt) currentFilter.createdAt = this.filter.createdAt
      if (this.filter.updatedAt) currentFilter.updatedAt = this.filter.updatedAt
      return currentFilter
    },
    getStatus (status) {
      if (this.$store.state.leadStatusListClear) {
        for (let i = 0; i < this.$store.state.leadStatusListClear.length; i++) {
          if (this.$store.state.leadStatusListClear[i].value === status) {
            return this.$store.state.leadStatusListClear[i].title
          }
        }
      }
    },
    goToPrevPage (e) {
      e.preventDefault()
      if (this.page !== 0) {
        this.page--
      }
      this.loadData()
    },
    // goToNextPage (e, dataLength) {
    //   e.preventDefault()
    //   if ((dataLength < this.perPage) || (this._allLeadsMeta === (this.page + 1) * this.perPage)) return
    //   this.page++
    //   this.loadData()
    // },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    // isDisabled (dataLength) {
    //   return (dataLength < this.perPage) || (this._allLeadsMeta === (this.page + 1) * this.perPage)
    // },
    sortingField2 (params) {
      if (params[0].type === 'none') {
        this.sort = {
          field: 'createdAt',
          order: 'desc'
        }
        return
      }
      this.sort.order = params[0].type
      this.sort.field = params[0].field
      this.loadData()
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
      this.loadData()
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order

      return currentClass
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    }
  }
}
</script>

<style lang="stylus" scoped>
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .sceleton
    position: relative
    display: inline-block
    height: 40px
    width: 100px
    margin: 0 10.5px 5px
    border-radius: 10px
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite;

    &--block
      width: 100%
      height: 250px
</style>
