<template>
  <div class="tbl tbl--divided">
    <div class="tbl__header">
      <div class="tbl__filter">
        <label class="tbl__filter__item">
          <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="Выберите создателя" @input="onCreatedByChange">
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon/>
              </div>
            </template>
          </v-select>
        </label>
        <label class="tbl__filter__item">
          <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="Выберите менеджера" @input="onUserChange">
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon/>
              </div>
            </template>
          </v-select>
        </label>
        <label class="tbl__filter__item">
          <v-select appendToBody placeholder="Выберите подразделения" :options="$store.state.allGroups.map(item => ({ value: item.id, label: item.name }))" @input="onGroupChange" multiple>
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon/>
              </div>
            </template>
          </v-select>
        </label>
        <label class="tbl__filter__item">
          <TagsInput @input="onTagsChanged"/>
        </label>
      </div>
      <div class="tbl__settings">
        <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
          <a class="tbl__setting" href="javascript:void(0)">
            <SettingsIcon/>
          </a>
        </mq-layout>
        <div class="tbl__type">
          <a :class="{ active: $store.state.data.trades.view === 'table'}" @click.prevent="$store.state.data.trades.view = 'table'" href="#">
            <TypeTable/>
          </a>
          <a :class="{ active: $store.state.data.trades.view === 'kanban'}" @click.prevent="$store.state.data.trades.view = 'kanban'" href="#">
            <TypeKanban/>
          </a>
        </div>
      </div>
    </div>
    <div class="sb" v-show="statusBoard">
      <ul class="sb__list">
        <li class="sb__container sb__container--red" data-action="remove">Удалить</li>
        <li class="sb__container sb__container--yellow" data-action="close">Закрыть</li>
        <li class="sb__container sb__container--green" data-action="approve">Одобрить</li>
      </ul>
    </div>
    <div class="tbl__container">
      <kanban-board
        @init="init"
        @over="overDrag"
        @drag="startDrag"
        @dragend="endDrag"
        :blocks="isLoading ? [] : $store.state.allLeadsKanban.filter(item => item.status !== 'Закрыт' && item.status !== 'Одобрен')"
        :stages="$store.state.leadStatusList.filter(item => item !== 'Все' && item !== 'Закрыт' && item !== 'Одобрен') || []"
        class="kanban-wrapper"
        id="leads-kanban"
        @update-block="update"
      >
        <template v-if="isLoading">
          <div
            class="trade-kanban sceleton sceleton--block"
            v-for="(stage, index) in $store.state.leadStatusList.filter(item => item !== 'Все' && item !== 'Закрыт' && item !== 'Одобрен')"
            :key="stage + Math.random() + index"
            :slot="stage"
          ></div>
        </template>
        <template v-else>
          <div :key="stage + i" :slot="stage" class="kanban-header" v-for="(stage, i) in $store.state.leadStatusList.filter(item => item !== 'Все' && item !== 'Закрыт' && item !== 'Одобрен')">
            <h3>{{ stage }}</h3>
            <div class="kanban-header-info">
              {{ getCountLeadOfStatus(stage) }} сделок: {{ prettifyPrice($store.state.allLeadsKanban.filter(item => item.status !== 'Закрыт' && item.status !== 'Одобрен').filter(item => item.status === stage).map(item => item.summ ? item.summ : 0).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)) }} руб
            </div>
          </div>
          <div :key="block._id + '-' + index" :slot="block._id" class="trade-kanban" v-for="(block, index) in $store.state.allLeadsKanban.filter(item => item.status !== 'Закрыт' && item.status !== 'Одобрен')">
            <div class="trade-kanban__header">
              <div class="trade-kanban__date">
                <i>
                  <ClockCircle/>
                </i>
                <span>{{ getDate(block.date) }}</span>
              </div>
              <div class="trade-kanban__contract">
                <div v-if="Object.keys(getContracts(block.contracts)).length > 0">
                  <router-link :to="{ name: 'contract', params: { id: getContracts(block.contracts).id } }">
                    {{ getContracts(block.contracts).name }}
                  </router-link>
                  <router-link v-if="getContracts(block.contracts).amount > 1" :to="{ name: 'contracts' }">
                    <span @click="setCompanyFilter(block.company)">
                      . . .
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="trade-kanban__body">
              <router-link :to="{ name: 'trade', params: { id: block._id } }" class="trade-kanban__product" v-if="block.type === 'lead'">
                {{ getProduct(block) }}
              </router-link>
              <a href="#" class="trade-kanban__product" v-else>
                {{ getProduct(block) }}
              </a>
              <div class="trade-kanban__manager">
                {{ getManager(block) }}
              </div>
            </div>
          </div>
        </template>
      </kanban-board>
      <scroll-loader :loader-method="loadTrades" :loader-disable="loadMore">
        <div>Загрузка...</div>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import CREATE_PROJECT from '@/graphql/mutations/CreateProject.gql'
import UPDATE_LEADS from '@/graphql/mutations/UpdateLeads.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import SettingsIcon from '@/components/svg/settings'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'
import ClockCircle from '@/components/svg/ClockCircle'
import DownIcon from '@/components/svg/DownIcon'

const _graphQlHelper = new GraphQLHelper()

export default {
  components: {
    DownIcon,
    ClockCircle,
    TypeKanban,
    TypeTable,
    SettingsIcon,
    TagsInput
  },
  name: 'LeadsKanban',
  props: {
    isFilters: {
      type: Boolean,
      required: true
    },
    createdBy: {
      type: Boolean,
      required: true
    },
    callCenter: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      stages: [],
      blocks: [],
      perPage: 10,
      loadMore: false,
      statusBoard: false,
      page: 0,
      prevLength: 0,
      isLoading: true,
      sort: {
        field: 'date',
        order: 'desc'
      },
      dragula: null,
      over: null,
      source: null,
      filter: {
        status: null,
        responsible_user_id: null,
        created_by: null,
        group_id: [],
        tags: [],
        callCenter: false,
        createdBy: false
      }
    }
  },
  created () {
    this.filter.callCenter = this.callCenter
    this.filter.createdBy = this.createdBy
    this.loadData(false)
  },
  watch: {
    'callCenter' (val) {
      this.filter.callCenter = val
      this.loadData()
    },
    'createdBy' (val) {
      this.filter.createdBy = val
      this.loadData()
    },
    '$store.state.loadMoreTrades' () {
      this.page++
      this.loadData(true)
    }
  },
  methods: {
    init (dragula) {
      this.dragula = dragula
      dragula.containers.push(...Array.from(document.querySelectorAll('.sb li')))
    },
    overDrag (el, container) {
      this.over = null
      document.querySelectorAll('.sb__container').forEach(item => {
        return item.classList.remove('active')
      })
      if (container.classList.contains('sb__container')) {
        container.classList.add('active')
        this.over = container
      }
    },
    endDrag (block, list, source, sibling) {
      this.statusBoard = false
    },
    startDrag (el, source) {
      this.statusBoard = true
      this.source = source
      this.over = null
    },
    loadTrades () {
      this.page++
      this.loadData(true).then(() => {
        if (this.prevLength === this.$store.state.allLeadsKanban.length) {
          this.loadMore = true
        } else {
          this.loadMore = false
          this.prevLength = this.$store.state.allLeadsKanban.length
        }
      })
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.loadData(false)
    },
    onGroupChange (e) {
      this.filter.group_id = e.map(item => item.value)
      this.loadData(false)
    },
    onUserChange (user) {
      if (user) {
        this.filter.responsible_user_id = user.value
      } else {
        this.filter.responsible_user_id = null
      }
      this.loadData(false)
    },
    onCreatedByChange (user) {
      if (user) {
        this.filter.created_by = user.value
      } else {
        this.filter.created_by = null
      }
      this.loadData(false)
    },
    async update (id, block, status) {
      if (this.over && this.over.classList.contains('sb__container')) {
        this.$store.state.checkedLeads = [id]
        switch (this.over.dataset.action) {
          case 'remove':
            await this.$store.dispatch('switchShowDeleteTradeModal')
            break
          case 'close':
            await this.$store.dispatch('switchShowCloseTradeModal')
            break
          case 'approve':
            await this.$apollo.mutate({
              mutation: UPDATE_LEADS,
              variables: {
                ids: this.$store.state.checkedLeads,
                status: 'approved'
              }
            }).then(() => {
              this.$router.go()
            }).catch(error => {
              _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
                this.$notify({
                  group: 'lsg-notify',
                  text: item
                })
                this.$notify({
                  group: 'lsg-notify-support',
                  title: 'Сообщить в поддержку',
                  text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
                  type: 'info',
                  closeOnClick: true,
                  data: {
                    info: {
                      project: 'Личный кабинет Leasing-trade',
                      name: this.$store.state.me.name,
                      email: this.$store.state.me.email,
                      group_id: this.$store.state.me.group.id,
                      role: this.$store.state.me.role,
                      localTime: new Date(),
                      system: navigator.userAgent,
                      url: window.location.href,
                      window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                      error: item,
                      comment: 'Ошибка при обновлении сделки в канбане'
                    },
                    style: 'cursor: pointer'
                  }
                })
              })
            })
            break
        }
        this.source.prepend(block)
      } else {
        status = this.$store.state.leadStatusListClear.find(x => x.title === status).value
        if (status === 'created') {
          await this.$apollo.mutate({
            mutation: CREATE_PROJECT,
            variables: {
              id
            },
            update: (_, { data }) => {
              if (data && data.createProject) {
                this.$router.push({ name: 'trade', params: { id } })
                this.$notify({
                  group: 'lsg-notify',
                  text: 'Данные успешно сохранены'
                })
              }
            }
          }).catch(error => {
            _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
              this.$notify({
                group: 'lsg-notify',
                text: item
              })
              this.$notify({
                group: 'lsg-notify-support',
                title: 'Сообщить в поддержку',
                text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
                type: 'info',
                closeOnClick: true,
                data: {
                  info: {
                    project: 'Личный кабинет Leasing-trade',
                    name: this.$store.state.me.name,
                    email: this.$store.state.me.email,
                    group_id: this.$store.state.me.group.id,
                    role: this.$store.state.me.role,
                    localTime: new Date(),
                    system: navigator.userAgent,
                    url: window.location.href,
                    window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                    error: item,
                    comment: 'Ошибка при обновлении сделки в канбане'
                  },
                  style: 'cursor: pointer'
                }
              })
            })
          })
        } else {
          this.$store.dispatch('updateLead', {
            context: this,
            data: {
              id: id,
              status: status
            }
          })
        }
      }
    },
    getProduct (item) {
      if (item.company) {
        return item.company.name
      } else if (item.product) {
        return item.product
      } else {
        return 'Нет компании'
      }
    },
    async loadData (isMore) {
      this.isLoading = true
      await this.$store.dispatch('allLeadsKanban', { context: this, variables: { page: this.page, perPage: this.perPage, filter: this.getTradeFilter(), sortField: this.sort.field, sortOrder: this.sort.order }, isMore: isMore }).then(() => {
        this.isLoading = false
        this.$store.state.loadMoreTrades = false
      })
    },
    getManager (item) {
      if (item.manager) {
        return item.manager.name
      } else if (item.responsible_user) {
        return item.responsible_user.name
      } else {
        return 'Нет менеджера'
      }
    },
    getTradeFilter () {
      let currentFilter = {}
      if (this.filter.status) currentFilter.status = this.filter.status
      if (this.filter.responsible_user_id) currentFilter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.created_by) currentFilter.created_by = this.filter.created_by
      if (this.filter.group_id.length) currentFilter.group_id = this.filter.group_id
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      if (this.filter.callCenter) currentFilter.callCenter = this.filter.callCenter
      if (this.filter.createdBy) currentFilter.created_by = '5ed9e0acc22e512fa8923ea1'
      return currentFilter
    },
    prettifyPrice (price) {
      if (price) {
        return Number(price).toLocaleString()
      }
      return 0
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    getCountLeadOfStatus (status) {
      let leads = this.$store.state.allLeadsKanban.filter(item => item.status === status)
      return leads.length ? leads[0].count.count : 0
    },
    setCompanyFilter (company) {
      this.$store.state.data.contracts.filter.partner.id = company._id
      this.$store.state.data.contracts.filter.partner.name = company.name
      this.$store.state.data.contracts.filter.partner.inn = company.inn
    },
    getContracts (contracts) {
      if (contracts.length) {
        const result = []
        contracts.forEach(el => { if (el) result.push(el) })
        return result.length ? { amount: result.length, id: result[0].id, name: result[0].name } : {}
      } else return {}
    }
  }
}
</script>

<style lang="stylus">
.sb
  fixed right bottom
  padding-left 280px
  z-index 99
  box-shadow: 0 4px 30px rgba($black, 0.15);
  width 100%
  height 100px
  background $white
  display flex
  justify-content center
  box-sizing border-box
  +below(980px)
    padding-left 0

  &__list
    display flex
    align-items center
    justify-content space-between
    width 100%
    padding 15px

  &__container
    border-radius: 5px;
    padding 15px 20px
    relative top -1px
    margin 0 10px
    box-sizing border-box
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    border: 1px dashed $darkgray
    height 100%
    width 100%
    display flex
    align-items center
    justify-content center
    background rgba($darkgray, 0.1)

    &--red.active
      border-color $red
      color $red
      background rgba($red, 0.1)

    &--yellow.active
      border-color $yellow
      color $yellow
      background rgba($yellow, 0.1)

    &--green.active
      border-color $green
      color $green
      background rgba($green, 0.1)

    .drag-item
      display none

@keyframes loading {
  0% {
    background-position: 100% 50% !important;
  }
  100% {
    background-position: 0 50% !important;
  }
}
.sceleton
  position: relative
  display: inline-block
  height: 40px
  // width: 100px
  margin: 0 10.5px 5px
  border-radius: 10px !important
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%) !important;
  background-size: 400%  !important
  animation: loading 1.2s ease-in-out infinite !important;

  &--block
    width: 100%
    height: 150px
</style>
