<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5734 16.4334V18.9334C20.5744 19.1655 20.5268 19.3952 20.4339 19.6079C20.3409 19.8205 20.2045 20.0114 20.0335 20.1683C19.8625 20.3252 19.6606 20.4446 19.4407 20.519C19.2209 20.5934 18.9879 20.621 18.7568 20.6001C16.1925 20.3214 13.7293 19.4452 11.5651 18.0417C9.55162 16.7623 7.84454 15.0552 6.5651 13.0417C5.15675 10.8678 4.2803 8.39258 4.00676 5.81675C3.98594 5.5863 4.01333 5.35405 4.08718 5.13477C4.16104 4.91549 4.27974 4.71399 4.43574 4.5431C4.59173 4.37221 4.7816 4.23567 4.99326 4.14218C5.20491 4.04869 5.43371 4.0003 5.6651 4.00008H8.1651C8.56952 3.9961 8.96159 4.13931 9.26823 4.40302C9.57487 4.66674 9.77516 5.03295 9.83176 5.43341C9.93728 6.23347 10.133 7.01902 10.4151 7.77508C10.5272 8.07335 10.5515 8.39751 10.485 8.70915C10.4186 9.02078 10.2641 9.30684 10.0401 9.53341L8.98176 10.5917C10.1681 12.678 11.8955 14.4055 13.9818 15.5917L15.0401 14.5334C15.2667 14.3094 15.5527 14.155 15.8644 14.0885C16.176 14.022 16.5002 14.0463 16.7984 14.1584C17.5545 14.4405 18.34 14.6362 19.1401 14.7417C19.5449 14.7989 19.9146 15.0028 20.1789 15.3147C20.4432 15.6266 20.5836 16.0247 20.5734 16.4334Z"></path>
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon'
}
</script>
