<template>
  <v-select appendToBody v-model="selected" placeholder="Выберите теги" @input="onInput" :options="$store.state.allTags.map(item => ({ value: item.id, label: item.name }))" multiple>
    <template slot="no-options">Нет результатов</template>
    <template #open-indicator="{ attributes }">
      <div v-bind="attributes">
        <DownIcon/>
      </div>
    </template>
  </v-select>
</template>

<script>

import DownIcon from '@/components/svg/DownIcon'
export default {
  name: 'TagsInput',
  components: { DownIcon },
  props: {
    tags: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      options: [],
      timeout: null,
      showLoading: false,
      query: '',
      selected: []
    }
  },
  created () {
    this.selected = JSON.parse(JSON.stringify(this.tags))
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
    }
  }
}
</script>
