<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7146 18.692C16.177 20.0064 14.181 20.8 12.0002 20.8C7.1402 20.8 3.2002 16.86 3.2002 12" stroke="#4E555A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"></path>
    <path d="M6.28564 5.3082C7.82324 3.9938 9.81925 3.2002 12 3.2002C16.86 3.2002 20.8 7.1402 20.8 12.0002" stroke="#4E555A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"></path>
    <path d="M20.8001 16.0002L17.6001 11.2002H24.0001L20.8001 16.0002Z" fill="#4E555A"></path>
    <path d="M3.2 8L6.4 12.8H0L3.2 8Z" fill="#4E555A"></path>
  </svg>
</template>

<script>
export default {
  name: 'SwitchIcon'
}
</script>
