<template>
  <div class="trade__calc calc">
    <div class="calc__item">
      <form class="calc__content" @submit.prevent="saveCompany" autocomplete="off">
        <table class="table trade_content__table">
          <tbody>
          <tr>
            <td>Наименование</td>
            <td>
              <CompanyInput
                  v-if="!data._id"
                  columnName="name"
                  :currentValue="graphItem.name"
                  filterName="q"
                  placeholder="Добавление компании"
                  @change="onCompanyChange"
                  @changeInput="onChangeInput"
              />
              <input v-else type="text" v-model="company.name">
            </td>
          </tr>
          <tr>
            <td>Телефон</td>
            <td>
              <div class="phones">
                <div :key="index" class="phones__item" v-for="(phone, index) in company.phones">
                  <div class="phones__phone">
                    <!-- <input title="79990006699" pattern="d{11}" type="tel" v-model="phone.value" @input="thisShouldTriggerRecompute"> -->
                    <input title="79990006699" type="text" v-model="phone.value" @input="thisShouldTriggerRecompute">
                  </div>
                  <div class="group_buttons">
                    <button type="button" @click="callPhone(phone.value)" class="group_button__item phone" v-if="phone.value">
                      <PhoneIcon/>
                    </button>
                    <button type="button" @click="removePhone($event, index)" class="group_button__item delete" v-if="index !== 0">
                      <PlusIco/>
                    </button>
                    <button type="button" @click="addPhone" class="group_buttons__item add">
                      <PlusIco/>
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <!--            <td>-->
            <!--              <CompanyInput-->
            <!--                  v-if="!data._id"-->
            <!--                  columnName="phone"-->
            <!--                  :currentValue="graphItem.phone"-->
            <!--                  filterName="q"-->
            <!--                  placeholder="Рабочий телефон"-->
            <!--                  @change="onCompanyChange"-->
            <!--                  @changeInput="onChangeInput"-->
            <!--              />-->
            <!--              <input v-else type="text" v-model="company.phone">-->
            <!--            </td>-->
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <CompanyInput
                  v-if="!data._id"
                  columnName="email"
                  :currentValue="graphItem.email"
                  filterName="q"
                  placeholder="Email"
                  @change="onCompanyChange"
                  @changeInput="onChangeInput"
              />
              <input v-else type="text" v-model="company.email">
            </td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>
              <CompanyInput
                  v-if="!data._id"
                  columnName="inn"
                  :currentValue="graphItem.inn"
                  filterName="q"
                  placeholder="ИНН"
                  @change="onCompanyChange"
                  @changeInput="onChangeInput"
              />
              <input v-else type="text" v-model="company.inn">
            </td>
          </tr>
          </tbody>
        </table>
        <button type="submit" class="button" v-if="!isChanged">Сохранить</button>
        <a v-if="isCanceled" href="#" class="button" @click="cancelEditCompany" style="margin-left:10px">Отмена</a>
      </form>
    </div>
  </div>
</template>

<script>
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import ADD_COMPANY from '@/graphql/mutations/AddCompany.gql'
import UPDATE_COMPANY from '@/graphql/mutations/UpdateCompany.gql'
import UPDATE_LEAD from '@/graphql/mutations/UpdateLead.gql'
import UPDATE_CONTACT from '@/graphql/mutations/UpdateContact.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import PlusIco from '../svg/plus'
import PhoneIcon from '../svg/phone'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Company',

  props: ['elementId', 'data', 'elementType', 'isCanceled'],
  data: () => {
    return {
      company: {
        name: '',
        phones: [],
        email: '',
        inn: ''
      },
      backup_phones: [],
      graphItem: {
        name: '',
        phones: [],
        email: '',
        city: ''
      },
      company_id: null
    }
  },
  computed: {
    isChanged () {
      if (this.data._id) {
        return (this.company.name === '' || this.company.name === this.data.name) &&
          JSON.stringify(this.company.phones) === JSON.stringify(this.backup_phones) &&
          (this.company.email === '' || this.company.email === this.data.email) &&
          (this.company.inn === '' || this.company.inn === this.data.inn)
      } else {
        return [this.company.name, this.company.email, this.company.inn].every(val => val === '') && JSON.stringify(this.company.phones) === JSON.stringify(this.backup_phones)
      }
    }
  },
  components: {
    PhoneIcon,
    PlusIco,
    CompanyInput
  },

  watch: {
    company (value) {
      if (
        value.name === '' &&
        (value.phones === [] || (value.phones[0] ?? value.phones[0].value === '')) &&
        value.email === '' &&
        value.inn === ''
      ) {
        this.company_id = null
      }
    }
  },

  methods: {
    getCurrentInputPhones (phones) {
      let currentPhones = []

      phones.forEach(phone => {
        currentPhones.push({ value: phone })
      })

      return currentPhones
    },
    getCurrentPhone () {
      let currentPhones = []

      this.company.phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone.value)
        }
      })

      return currentPhones
    },
    thisShouldTriggerRecompute () {
      this.forceRecomputeCounter++
    },
    addPhone () {
      this.company.phones.push({ value: '' })
    },
    removePhone (e, index) {
      e.preventDefault()

      this.company.phones.splice(index, 1)
    },
    async saveCompany (e) {
      e.preventDefault()
      let currentInput = {
        name: this.company.name,
        email: this.company.email,
        phone: this.company.phones.map(item => item.value),
        inn: this.company.inn
      }

      if (this.data._id) currentInput['id'] = this.data._id
      if (this.company_id !== null && !this.data._id) currentInput['id'] = this.company_id

      await this.$apollo.mutate({
        mutation: this.data._id || this.company_id !== null ? UPDATE_COMPANY : ADD_COMPANY,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data && data.addCompany && data.addCompany._id) {
            this.addCompanyInElement(data.addCompany._id)
          }
          if (this.company_id !== null && !this.data._id) {
            this.addCompanyInElement(data.updateCompany._id)
          }
          this.$router.go()
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async addCompanyInElement (companyId) {
      if (!this.elementId) {
        this.$emit('create', companyId)
        return
      }
      let mutation = this.elementType === 'Lead' ? UPDATE_LEAD : UPDATE_CONTACT

      if (companyId) {
        await this.$apollo.mutate({
          mutation: mutation,
          variables: {
            input: {
              id: this.elementId,
              company_id: companyId
            }
          },
          update: (store, { data }) => {
            if (data) this.$router.go()
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    onCompanyChange (item) {
      this.graphItem = item
      this.company_id = item._id
      this.company.name = item.name
      this.company.phones = this.getCurrentInputPhones(item.phone || [])
      this.company.email = item.email
      this.company.inn = item.inn

      if (this.company.phones.length === 0) {
        this.company.phones.push({ value: '' })
      }
    },
    onChangeInput ({ columnName, value }) {
      this.company[columnName] = value
    },
    cancelEditCompany (e) {
      e.preventDefault()

      this.$emit('cancel')
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    }
  },

  mounted () {
    if (this.data._id) {
      this.company.name = this.data.name
      this.company.email = this.data.email
      this.company.inn = this.data.inn
      this.company_id = this.data._id
      this.company.phones = this.getCurrentInputPhones(this.data.phone || [])
    }

    if (this.company.phones.length === 0) {
      this.company.phones.push({ value: '' })
    }
    this.backup_phones = JSON.parse(JSON.stringify(this.company.phones))
  }
}
</script>
