<template>
  <div class="lsg-tasks">
    <div class="main__header">
      <div class="main__header__item left">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide">
          <span class="btn btn_ico" @click="$store.state.data.trades.showFilter = !$store.state.data.trades.showFilter" :class="{'disable': $store.state.data.trades.showFilter}">
            <FilterIco/>
            Скрыть фильтры
          </span>
        </mq-layout>
        <mq-layout :mq="['xlg', 'xl']" class="filter_hide">
          <div class="small-buttons">
            <a class="btn btn--small" href="#" @click.prevent="callCenter = !callCenter" :class="{ active: callCenter }" v-tooltip="callCenterTitle">
              <PhoneIcon/>
            </a>
            <a class="btn btn--small" href="#" @click.prevent="createdBy = !createdBy" :class="{ active: createdBy }" v-tooltip="internetTitle">
              <InternetIcon/>
            </a>
            <a class="btn btn--small" href="#" @click.prevent="form = !form" :class="{ active: form }" v-tooltip="formTitle">
              <FormIcon/>
            </a>
          </div>
        </mq-layout>
      </div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <button class="btn" type="button" @click="createTrade">
          <PlusIco/>
          <span>Добавить сделку</span>
        </button>
      </div>
    </div>
    <div class="main__header main__header--secondary" v-if="isTableView && displayFilders">
      <div class="toper__item">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="small-buttons">
          <a class="btn btn--small" href="#" @click.prevent="callCenter = !callCenter" :class="{ active: callCenter }">
            <PhoneIcon/>
          </a>
          <a class="btn btn--small" href="#" @click.prevent="createdBy = !createdBy" :class="{ active: createdBy }">
            <InternetIcon/>
          </a>
        </mq-layout>
      </div>
      <div class="toper__item">
        <div class="button-group">
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'today' }" @click="setSortType('today')">
            <span>Сегодня</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'tomorrow' }" @click="setSortType('tomorrow')">
            <span>Вчера</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'week' }" @click="setSortType('week')">
            <span>Неделя</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'month' }" @click="setSortType('month')">
            <span>Месяц</span>
          </button>
          <button type="button" @click="openPeriod = true" :class="{ active: $store.state.data.trades.sort2 === 'period' && $store.state.data.trades.sortDate }" ref="buttonPeriod" v-click-outside="vcoConfig">
            <i>
              <CalendarIcon/>
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker v-model="$store.state.data.trades.sortDate" ref="pickerPeriod" inline range/>
            </b>
          </button>
        </div>
      </div>
      <div class="toper__item"></div>
    </div>
    <div class="main__content">
      <trade-table :isFilters="isFilters" v-if="isTableView" :callCenter="callCenter" :createdBy="createdBy" :form="form"/>
      <trades-kanban :isFilters="isFilters" v-if="isKanbanView" :callCenter="callCenter" :createdBy="createdBy" :form="form"/>
    </div>
    <LsgModal @close="switchShowCloseTradeModal" v-if="showCloseTradeModal">
      <template slot="header">
        <h3>Укажите причину</h3>
      </template>
      <template slot="body">
        <div class="form__item">
          <select id="reason" title="Причина" v-model="closeForm.reason">
            <option :value="null" disabled>Выберите причину</option>
            <option value="Ушел к конкурентам">Ушел к конкурентам</option>
            <option value="Отказался">Отказался</option>
          </select>
        </div>
        <div class="form__item">
          <textarea placeholder="Комментарий" title="Комментарий" v-model="closeForm.comment"></textarea>
        </div>
      </template>
      <template slot="footer">
        <button @click="closeTrades" class="button">Сохранить</button>
        <button @click="switchShowCloseTradeModal" class="button">Отмена</button>
      </template>
    </LsgModal>
    <LsgModal @close="switchShowDeleteTradeModal" attention v-if="showDeleteTradeModal">
      <template slot="ico">
        <AttentionIco/>
      </template>
      <template slot="body">
        <h2>Вы уверены?</h2>
        <p>После удаления данные не могут быть восстановлены</p>
      </template>
      <template slot="footer">
        <button @click="removeTrades" class="button button__red">Удалить</button>
        <button @click="switchShowDeleteTradeModal" class="button">Отмена</button>
      </template>
    </LsgModal>
    <LsgModal
      @close="switchShowAddTradeModal"
      v-if="showAddTradeModal"
    >
      <template slot="header">
        <h3>Выберите компанию</h3>
      </template>
      <template slot="body">
        <Company
          :data="{}"
          :isCanceled="true"
          @cancel="switchShowAddTradeModal"
          @create="addCompanyInLead"
        ></Company>
      </template>
      <template slot="footer">
        <div></div>
      </template>
    </LsgModal>
  </div>
</template>

<script>
import LsgModal from '@/components/ui/LsgModal'
import Company from '@/components/ui/Company'
import moment from 'moment'
import ADD_LEAD from '@/graphql/mutations/AddLead.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import TradeTable from './components/TradeTable'
import TradesKanban from './components/TradeKanban'
import AttentionIco from '@/components/svg/attention'
import UPDATE_LEADS from '@/graphql/mutations/UpdateLeads.gql'
import DELETE_LEADS from '@/graphql/mutations/DeleteLeads.gql'
import CalendarIcon from '@/components/svg/CalendarIcon'
import PlusIco from '@/components/svg/plus'
import PhoneIcon from '@/components/svg/phone'
import InternetIcon from '@/components/svg/InternetIcon'
import FormIcon from '@/components/svg/FormIcon'
import FilterIco from '@/components/svg/new/Filter'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Leads',
  data () {
    return {
      title: 'Сделки',
      view: 'table',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Сделки'
        }
      ],
      isFilters: false,
      closeForm: {
        reason: null,
        comment: null
      },
      openPeriod: false,
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      },
      isActive: false,
      showFilter: false,
      callCenter: false,
      callCenterTitle: 'Создатель call-центр',
      createdBy: false,
      internetTitle: 'Создатель Ройстат',
      form: false,
      formTitle: 'Интернет-форма'
    }
  },
  components: {
    InternetIcon,
    PhoneIcon,
    PlusIco,
    CalendarIcon,
    TradesKanban,
    TradeTable,
    LsgModal,
    Company,
    AttentionIco,
    DatePicker,
    FormIcon,
    FilterIco
  },
  computed: {
    displayFilders () {
      if (this.$mq === 'sm' || this.$mq === 'xsm' || this.$mq === 'md' || this.$mq === 'lg') {
        return this.$store.state.data.trades.showFilter
      } else {
        return true
      }
    },
    isTableView () {
      return this.$store.state.data.trades.view === 'table'
    },
    isKanbanView () {
      return this.$store.state.data.trades.view === 'kanban'
    },
    showDeleteTradeModal () {
      return this.$store.state.showDeleteTradeModal
    },
    showCloseTradeModal () {
      return this.$store.state.showCloseTradeModal
    },
    showAddTradeModal () {
      return this.$store.state.showAddTradeModal
    }
  },
  watch: {
    '$store.state.data.trades.sortDate' () {
      if (this.$store.state.data.trades.sortDate) {
        this.$store.state.data.trades.sort2 = 'period'
      } else {
        this.$store.state.data.trades.sort2 = null
      }
      this.openPeriod = false
    },
    '$store.state.data.trades.view' () {
      window.location.hash = this.$store.state.data.trades.view
    }
  },
  created () {
    if (window.location.hash === '') {
      this.$store.state.data.trades.view = 'table'
      window.location.hash = this.$store.state.data.trades.view
    } else {
      this.$store.state.data.trades.view = window.location.hash.substr(1)
    }
  },
  methods: {
    setSortType (type) {
      if (this.$store.state.data.trades.sort2 === type) {
        this.$store.state.data.trades.sort2 = null
      } else {
        this.$store.state.data.trades.sort2 = type
      }
    },
    async createTrade () {
      await this.$store.dispatch({ type: 'switchShowAddTradeModal' })
    },
    async removeTrades () {
      await this.$apollo.mutate({
        mutation: DELETE_LEADS,
        variables: {
          ids: this.$store.state.checkedLeads
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          this.$notify({
            group: 'lsg-notify-support',
            title: 'Сообщить в поддержку',
            text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
            type: 'info',
            closeOnClick: true,
            data: {
              info: {
                project: 'Личный кабинет Leasing-trade',
                name: this.$store.state.me.name,
                email: this.$store.state.me.email,
                group_id: this.$store.state.me.group.id,
                role: this.$store.state.me.role,
                localTime: new Date(),
                system: navigator.userAgent,
                url: window.location.href,
                window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                error: item,
                comment: 'Ошибка при удалении сделок в таблице'
              },
              style: 'cursor: pointer'
            }
          })
        })
      })
    },
    async closeTrades () {
      if (this.closeForm.reason) {
        await this.$apollo.mutate({
          mutation: UPDATE_LEADS,
          variables: {
            ids: this.$store.state.checkedLeads,
            reason: this.closeForm.reason,
            comment: this.closeForm.comment,
            status: 'closed'
          }
        }).then(() => {
          this.$router.go()
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при закрытии сделок в таблице'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Выберите причину'
        })
      }
    },
    goToPrevPage (e) {
      e.preventDefault()
      if (this.page !== 0) {
        this.page--
      }
    },
    goToNextPage (e, dataLength) {
      e.preventDefault()
      if ((dataLength < this.perPage) || (this._allLeadsMeta === (this.page + 1) * this.perPage)) return
      this.page++
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    isDisabled (dataLength) {
      return (dataLength < this.perPage) || (this._allLeadsMeta === (this.page + 1) * this.perPage)
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order

      return currentClass
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    switchShowDeleteTradeModal () {
      this.$store.dispatch({ type: 'switchShowDeleteTradeModal' })
    },
    switchShowAddTradeModal () {
      this.$store.dispatch({ type: 'switchShowAddTradeModal' })
    },
    switchShowCloseTradeModal () {
      this.$store.dispatch({ type: 'switchShowCloseTradeModal' })
    },
    async addCompanyInLead (companyId) {
      await this.$apollo.mutate({
        mutation: ADD_LEAD,
        variables: {
          input: {
            status: 'first',
            company_id: companyId
          }
        },
        update: (store, { data: { addLead } }) => {
          this.switchShowAddTradeModal()
          if (addLead && addLead._id) {
            this.$router.push({ name: 'trade', params: { id: addLead._id } })
          }
        }
      }).catch(error => {
        this.switchShowAddTradeModal()
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          this.$notify({
            group: 'lsg-notify-support',
            title: 'Сообщить в поддержку',
            text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
            type: 'info',
            closeOnClick: true,
            data: {
              info: {
                project: 'Личный кабинет Leasing-trade',
                name: this.$store.state.me.name,
                email: this.$store.state.me.email,
                group_id: this.$store.state.me.group.id,
                role: this.$store.state.me.role,
                localTime: new Date(),
                system: navigator.userAgent,
                url: window.location.href,
                window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                error: item,
                comment: 'Ошибка при создании сделки в таблице'
              },
              style: 'cursor: pointer'
            }
          })
        })
      })
    }
  }
}
</script>
<style lang="stylus">
.trade-kanban
  background: $white;
  border: 1px solid rgba($darkgray, 0.3);
  box-sizing: border-box;
  box-shadow: 0 7px 30px rgba($black, 0.16);
  border-radius: 10px;
  margin-bottom 10px

  &__product
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $black;
    margin-bottom 18px

  &__manager
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    display: inline-flex;
    align-items: center;
    color: $c4E555A;
    padding 8px 15px
    border: 1px solid $darkgray;
    box-sizing: border-box;
    border-radius: 10px;

  .checkbox-component > input.active + label > .input-box
    border-color $orange !important
    background $orange

  .checkbox-component > input + label > .input-box
    width 15px
    border-width 1.5px !important
    border-color $c7A !important
    border-radius 3px
    box-shadow none
    height 15px

    & > .input-box-tick > path
      stroke $white

  &__header
    display flex
    justify-content space-between
    border-bottom 1px solid $pinky
    padding 15px 20px

  &__body
    display flex
    flex-direction column
    align-items flex-start
    padding 20px

  &__date
    display flex
    align-items center

    i
      width 20px
      height 20px
      margin-right 10px

      svg
        width 100%
        height 100%

    span
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: $c7A;
</style>
